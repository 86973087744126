import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import pasteleriaHeading from "../images/pasteleria-heading.svg"
import "../css/index.css";
import { useQueryParam, StringParam } from "use-query-params";
import { FormButton, FormLinkButton } from "../components/form-elements";
import { useProducts } from "../hooks/use-products";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
export default function IndexPage() {
  let element = <></>;
  const [redirect] = useQueryParam("redirect", StringParam);
  switch (redirect) {
    case "success": navigate("/checkout-success/"); break;
    case "cancel": navigate("/checkout-review/"); break;
    default:
      element = <>
        <Seo title="Home" />
        <BannerDesktop></BannerDesktop>
        <BannerMobile></BannerMobile>
      </>;
      break;
  }
  return (<>{element}</>)
}

function BannerDesktop() {
  return (
    /* <main className="md:flex hidden h-[calc(100vh_-_15rem)] min-h-[15rem]" > */
    <main className="md:flex hidden" >
      <div className="md:flex hidden items-center max-w-2xl lg:max-w-3xl mx-auto
        h-full max-h-[30rem]">
        <CakeBanner />
        <div className="w-5/12 text-left
            ml-5 lg:ml-10 
            flex flex-col justify-around 
            h-full max-h-64">
          <div className="mb-7 pt-10">
            <WelcomeMessage />
            <PasteleriaHeading />
            <SubHeader />
          </div>
          <OrderNow />
        </div>
      </div>
    </main>
  );
}

function SubHeader() {
  return (
    <p className="px-4 sm:px-0 mt-4 md:mt-8 text-center max-w-sm md:max-w-none md:text-center text-xs leading-5 tracking-widest font-ovo text-pas-gray">Bespoke cakes and handcrafted delights with gluten-friendly,
      dairy-free & vegan options!</p>
  )
}

function BannerMobile() {
  return (
    // <main className="md:hidden flex flex-col justify-center items-center h-[calc(100vh_-_10rem)]">
    <main className="md:hidden flex flex-col justify-center items-center">
      <div className="mt-6">
        <WelcomeMessage />
      </div>
      <div className="mb-2">
        <PasteleriaHeading />
      </div>

      <CakeBanner />
      <SubHeader />
      <div className="mb-3 mt-9">

        <OrderNow />
      </div>
    </main>
  );
}

function WelcomeMessage() {
  return (
    <>
      <p className="pt-0 mb-7 text-xs uppercase tracking-widest font-ovo text-pas-gray text-center md:pr-3">
        Welcome
        <p className="lowercase mt-1">to</p>
      </p>
    </>
  );
}

function PasteleriaHeading() {
  return (
    <img src={pasteleriaHeading} className="Header max-h-[calc(10vh)]" alt="Pasteleria" />
  );
}

function CakeBanner() {
  const products = useProducts()

  const images = products.filter(x =>
    x.metadata.banner == "true").map(cake => {

      const image = getImage(cake.localImage[0])

      return <div key={cake.id} className="banner-image">
        <GatsbyImage image={image} alt={cake.name} />
      </div>
    })
  return (
    <div className="w-11/12 md:w-7/12 flex justify-center">
      <div id="cf" className="flex items-center">
        {images}
        {/* <div className="banner-image">
          <StaticImage
            src="../images/cakes/gallery_starwars.png"
            quality={100}
            height={500}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="Pasteleria Cake" />
        </div> */}
      </div>
    </div>
  );
}

function OrderNow() {
  return (<FormLinkButton label={"ORDER NOW"} to={"/cakes"} isSecondary={true} className={"font-ovo text-sm text-center tracking-widest w-fit mx-auto text-pas-gray"} />);
}






